// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-coach-page-index-tsx": () => import("./../src/pages/CoachPage/index.tsx" /* webpackChunkName: "component---src-pages-coach-page-index-tsx" */),
  "component---src-pages-coder-page-index-tsx": () => import("./../src/pages/CoderPage/index.tsx" /* webpackChunkName: "component---src-pages-coder-page-index-tsx" */),
  "component---src-pages-coder-references-page-index-tsx": () => import("./../src/pages/CoderReferencesPage/index.tsx" /* webpackChunkName: "component---src-pages-coder-references-page-index-tsx" */),
  "component---src-pages-connect-page-index-tsx": () => import("./../src/pages/ConnectPage/index.tsx" /* webpackChunkName: "component---src-pages-connect-page-index-tsx" */),
  "component---src-pages-do-together-page-index-tsx": () => import("./../src/pages/DoTogetherPage/index.tsx" /* webpackChunkName: "component---src-pages-do-together-page-index-tsx" */),
  "component---src-pages-entrepreneur-page-index-tsx": () => import("./../src/pages/EntrepreneurPage/index.tsx" /* webpackChunkName: "component---src-pages-entrepreneur-page-index-tsx" */),
  "component---src-pages-home-page-index-tsx": () => import("./../src/pages/HomePage/index.tsx" /* webpackChunkName: "component---src-pages-home-page-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-love-to-do-page-index-tsx": () => import("./../src/pages/LoveToDoPage/index.tsx" /* webpackChunkName: "component---src-pages-love-to-do-page-index-tsx" */)
}

